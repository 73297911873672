import React, { ReactNode, useEffect } from "react";
import { AuthProvider, useAuth } from "~/utils/context/auth";
import { Loader } from "~/components/Utility/Loader";
import { useRouter } from "next/router";
import Logo from "~/assets/july-wordmark-light.svg";
import { SlideFade } from "~/components/Utility/SlideFade";

export interface AuthLayoutProps {
	title?: string;
	children: React.ReactNode;
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
	return (
		<AuthProvider expectUnauthenticated={true}>
			<AuthLayoutInner>{children}</AuthLayoutInner>
		</AuthProvider>
	);
};

const AuthLayoutInner: React.FC<{ children: ReactNode }> = ({ children }) => {
	const router = useRouter();
	const { user, isLoading, token } = useAuth();

	useEffect(() => {
		if (!user || !router.isReady) {
			return;
		}

		if (router.query.next) {
			router.push(router.query.next as string);
		} else {
			router.push("/creator/mediakit");
		}
	}, [user, router]);

	if ((isLoading || user) && token) {
		return <Loader />;
	}

	return (
		<main
			id="main"
			className="bg-surface-primary flex h-full w-full flex-col items-center"
		>
			<div className="flex min-h-[88px] w-full flex-col items-center justify-center lg:h-[72px]">
				<img className="h-6 w-auto" src={Logo.src} alt="July Beta" />
			</div>

			<div className="relative flex h-full w-full flex-col items-center justify-center">
				<SlideFade key={router.route} offsetX={64} className="h-full w-full">
					{children}
				</SlideFade>
			</div>
		</main>
	);
};
